import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { TranscriptRecord } from '@clients-nside-io/shared/models';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TipsDialogComponent } from '../tips-dialog/tips-dialog.component';

@Component({
  selector: 'clients-nside-io-tips-card',
  templateUrl: './tips-card.component.html',
  styleUrls: ['./tips-card.component.scss'],
})
export class TipsCardComponent implements AfterViewInit, OnInit {
  constructor(private ref: ChangeDetectorRef, private dialog: MatDialog) {}
  @Input() tip: any;
  @Input() audioLink;
  @ViewChild('transcript') element: ElementRef;
  opened = false;
  isPlaying = false;
  collapseBtnShow: boolean;
  destroy$ = new Subject();

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  ngOnInit() {
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe(() => {
      this.collapseBtnShow = this.checkOverflow(this.element);
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.collapseBtnShow = this.checkOverflow(this.element);
      this.ref.detectChanges();
    });
  }

  ngOnDestroy() {
    this.resizeSubscription$.unsubscribe();
  }

  togglePlay(element: HTMLAudioElement) {
    this.isPlaying ? element.pause() : element.play();
    this.isPlaying = !this.isPlaying;
  }

  checkOverflow(element: ElementRef) {
    const { offsetHeight, scrollHeight } = element.nativeElement;
    this.ref.detectChanges();
    if (offsetHeight < scrollHeight) {
      return true;
    } else {
      return false;
    }
  }

  toggleOpen() {
    this.opened = !this.opened;
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.width = '400px';

    dialogConfig.data = this.tip;

    this.dialog.open(TipsDialogComponent, dialogConfig);
  }
}
