import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
//eslint-disable-next-line
import { environment } from '../environments/environment';

import { BehaviorSubject, combineLatest, forkJoin, of, throwError } from 'rxjs';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  map,
  mergeMap,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';
import { AuthorizationService } from '@clients-nside-io/shared/auth';
import {
  FilterModel,
  FilterObject,
  NotifyItem,
  NotifyItemDetail,
  TipFilterState,
  TipInteractionModel,
  TipItem,
} from '@clients-nside-io/shared/models';
// import { NotifyItem } from '../../models/NotifyItem';
const initFilters: FilterObject[] = [
  {
    op: 'gte',
    field: 'notifyItemType',
    value: 200,
  },
  {
    op: 'lte',
    field: 'notifyItemType',
    value: 299,
  },
];

@Injectable({
  providedIn: 'root',
})
export class TipsService {
  constructor(
    private http: HttpClient,
    private authService: AuthorizationService
  ) {}

  userName$ = this.authService.userName$;
  private url$ = new BehaviorSubject<string>('');
  private buildingIdFromRoute$ = this.authService.buildingId$.pipe(
    map((id) => Number(id))
  );
  private statusFilters$ = new BehaviorSubject<FilterObject[]>(initFilters);
  tips$: any = combineLatest([
    this.url$,
    this.statusFilters$,
    this.buildingIdFromRoute$,
  ]).pipe(
    switchMap(([url, filters, buildingId]) => {
      const activeFilters: FilterObject[] = [...filters];
      const model: FilterModel = {
        op: 'And',
        filters: activeFilters,
      };

      const filterModel = JSON.stringify(model);
      const obj = {
        params: new HttpParams().set('filterModel', filterModel),
      };

      return this.http
        .get<NotifyItem>(
          url
            ? url
            : `${environment.notifyApiBase}/${environment.notifyApiVersion}/Items/
            ${buildingId}/Recent/10?minimumStatus=InitialFormSubmission`,
          // new URL(
          //   [
          //     environment.notifyApiVersion,
          //     'Items/Recent/10?minimumStatus=ProcessingFormSubmission',
          //   ].join('/'),
          //   environment.notifyApiBase
          // ).toString(),
          url ? undefined : obj
        )
        .pipe(
          catchError((err) => throwError(err)),
          concatMap((notifyItem) => {
            if (!notifyItem.data) return of([]);
            return forkJoin(
              notifyItem.data.map((item) =>
                this.http
                  .get<NotifyItemDetail>(
                    `${environment.notifyApiBase}/${environment.notifyApiVersion}/Items/${item.data.id}`
                  )
                  .pipe(
                    catchError((err) => throwError(err)),
                    map((res) => {
                      let theDetail = null;
                      const theDetails = res?.data?.details;

                      if (Array.isArray(theDetails) && theDetails.length > 0) {
                        theDetail = theDetails
                          .sort(
                            (a, b) =>
                              new Date(b['updated']).getTime() -
                              new Date(a['updated']).getTime()
                          )
                          .find((d) =>
                            [
                              'Processing_Form_Submitted',
                              'Processing_Form_Submission',
                              'Initial_Form_Submission',
                            ].includes(d.type)
                          );
                      }
                      return {
                        ...item,
                        detailRecord: res,
                        ...theDetail,
                      };
                    }),
                    map((d) => {
                      const parsed = JSON.parse(d.json);
                      if (Array.isArray(parsed)) {
                        return {
                          ...d,
                          rawTip: parsed,
                        };
                      } else {
                        return {
                          ...d,
                          rawTip: [parsed],
                        };
                      }
                    })
                  )
              )
            ).pipe(
              map((tips) => ({
                ...notifyItem,
                tips,
              }))
            );
          })
        );
    }),
    shareReplay(1)
  );

  filterTips(filter: TipFilterState) {
    let filterObj: FilterObject[];
    const filterValue = Number(filter);
    //if its All
    if (isNaN(filterValue)) {
      filterObj = [
        {
          op: 'gte',
          field: 'notifyItemType',
          value: 200,
        },
        {
          op: 'lte',
          field: 'notifyItemType',
          value: 299,
        },
      ];
      this.statusFilters$.next(filterObj);
      return;
    }
    switch (filterValue) {
      case TipFilterState.Closed:
        filterObj = [
          {
            op: 'lte',
            field: 'status',
            value: filterValue,
          },
          {
            op: 'gte',
            field: 'notifyItemType',
            value: 200,
          },
          {
            op: 'lte',
            field: 'notifyItemType',
            value: 299,
          },
        ];
        this.statusFilters$.next(filterObj);
        break;
      case TipFilterState.InReview:
        filterObj = [
          {
            op: 'gte',
            field: 'status',
            value: filterValue,
          },
          {
            op: 'lte',
            field: 'status',
            value: 269,
          },
          {
            op: 'gte',
            field: 'notifyItemType',
            value: 200,
          },
          {
            op: 'lte',
            field: 'notifyItemType',
            value: 299,
          },
        ];
        this.statusFilters$.next(filterObj);
        break;
      case TipFilterState.Opened:
        filterObj = [
          {
            op: 'gte',
            field: 'status',
            value: filterValue,
          },
          {
            op: 'gte',
            field: 'notifyItemType',
            value: 200,
          },
          {
            op: 'lte',
            field: 'notifyItemType',
            value: 299,
          },
        ];
        this.statusFilters$.next(filterObj);
        break;
      default:
        filterObj = [
          {
            op: 'gte',
            field: 'notifyItemType',
            value: 200,
          },
          {
            op: 'lte',
            field: 'notifyItemType',
            value: 299,
          },
        ];
        this.statusFilters$.next(filterObj);
        break;
    }
  }

  emitNextUrl(url: string) {
    this.url$.next(url);
  }

  postTipInfo(tipModel: TipInteractionModel) {
    const { notifyItemId } = tipModel;
    const user = tipModel.user.email;

    const params = {
      params: new HttpParams().set('email', user),
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    const { notes } = tipModel;

    return this.http.post(
      `${environment.notifyApiBase}/${environment.notifyApiVersion}/Tips/Update/Notes/${notifyItemId}`,
      JSON.stringify(notes),
      params
    );
  }

  assignUser(assignedUserModel: TipInteractionModel) {
    const { notifyItemId } = assignedUserModel;
    return this.http
      .post(
        `${environment.notifyApiBase}/${environment.notifyApiVersion}/Tips/Update/UserAssignment/${notifyItemId}`,
        assignedUserModel
      )
      .pipe(
        tap((r) => {
          const lastUrl = this.url$.getValue();
          this.emitNextUrl(lastUrl);
        })
      );
  }

  closeTip(model: TipInteractionModel) {
    const { notifyItemId } = model;
    const user = model.user.email;

    const params = {
      params: new HttpParams().set('email', user),
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    const notes = model.notes ? model.notes : 'Closed no note';

    return this.http
      .post(
        `${environment.notifyApiBase}/${environment.notifyApiVersion}/Tips/Update/Close/${notifyItemId}`,
        JSON.stringify(notes),
        params
      )
      .pipe(
        tap((r) => {
          const lastUrl = this.url$.getValue();
          this.emitNextUrl(lastUrl);
        })
      );
  }
}
