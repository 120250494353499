import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuildingIdSelectionComponent } from './building-id-selection/building-id-selection.component';

@NgModule({
  declarations: [BuildingIdSelectionComponent],
  imports: [CommonModule],
  exports: [BuildingIdSelectionComponent],
})
export class SharedComponentsModule {}
