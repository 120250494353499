import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioButton } from '@angular/material/radio';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { TipsService } from '../tips.service';
import { TipInteractionModel, TipModel } from '@clients-nside-io/shared/models';

@Component({
  selector: 'clients-nside-io-nside-notify-tips-dialog',
  templateUrl: './tips-dialog.component.html',
  styleUrls: ['./tips-dialog.component.scss'],
})
export class TipsDialogComponent {
  tip: any;
  status: string;
  userNotes: string;
  notes: any[];
  userName$ = this.tipsService.userName$;
  constructor(
    @Inject(MAT_DIALOG_DATA) tip: any,
    private dialogRef: MatDialogRef<any>,
    private tipsService: TipsService
  ) {
    this.tip = tip;
    if (this.tip.detailRecord.data?.tipStatus === 'Open') {
      this.status = 'open';
    }
    if (this.tip.detailRecord.data?.tipStatus === 'Closed') {
      this.status = 'closed';
    }
    if (this.tip.detailRecord.data?.tipStatus === 'InReview') {
      this.status = 'review';
    }
    const closedTips = this.tip.detailRecord.data.details.filter(
      (t) => t.type === 'Processing_Tip_Closed_NoAction'
    );
    const tipsWithNotes = this.tip.detailRecord.data.details.filter(
      (t) => t.type === 'Processing_Tip_Notes'
    );

    const rawJSON = [...closedTips, ...tipsWithNotes];
    if (rawJSON.length > 0) {
      const t = rawJSON.map((t) => JSON.parse(t.json));
      const b = t.flat();
      //format = JSON.parse(rawJSON);
      const notes = b.map((f) => f.data);
      this.notes = notes;
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  save(userName: string) {
    const assignUserModel: TipInteractionModel = {
      assignedUser: { email: userName },
      notifyItemId: this.tip.itemId,
      user: { email: userName },
    };

    const model: TipInteractionModel = {
      notifyItemId: this.tip.itemId,
      notes: this.userNotes,
      user: { email: userName },
    };
    if (this.status === 'closed') {
      this.tipsService.closeTip(model).subscribe();
    }
    if (this.userNotes && this.status !== 'closed') {
      this.tipsService.postTipInfo(model).subscribe();
    }
    if (this.status === 'review') {
      this.tipsService.assignUser(assignUserModel).subscribe();
    }
    if (this.status === 'open') {
      const removeAssignmentModel: TipInteractionModel = {
        assignedUser: { email: userName },
        notifyItemId: this.tip.itemId,
        user: { email: userName },
        remove: true,
      };
      this.tipsService.assignUser(removeAssignmentModel).subscribe();
    }
    this.dialogRef.close();
  }
}
