import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthorizationService } from '@clients-nside-io/shared/auth';
import { LocalStoreService } from '@clients-nside-io/shared/services';

@Component({
  selector: 'clients-nside-io-building-id-selector',
  templateUrl: './building-id-selection.component.html',
  styleUrls: ['./building-id-selection.component.scss'],
})
export class BuildingIdSelectionComponent {
  constructor(
    private authService: AuthorizationService,
    private localStore: LocalStoreService,
    private router: Router
  ) {}
  buildingId$: Observable<number> = this.authService.buildingId$.pipe(
    map((v) => (v && v[0] !== 0 ? v : null)),
    map((id) => {
      // const path = localStorage.getItem('route');
      const path = this.localStore.currentRoute;
      if (id && path) {
        this.router.navigateByUrl(`${path}/${id}`);
      }
      return id;
    })
  );
}