import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { defaultIfEmpty, filter, map, pluck, shareReplay, take, tap } from 'rxjs/operators';
import { IUserDataInfo, User } from '@clients-nside-io/shared/models';
import { DebugUtils } from '@clients-nside-io/shared/util';
import { isNumeric } from 'rxjs/internal-compatibility';
import { LocalStoreService } from '@clients-nside-io/shared/services';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  constructor(private oidcService: OidcSecurityService,
              private localStore: LocalStoreService,
              private debug: DebugUtils) {}
  buildingIdSubject = new BehaviorSubject<string>(
    this.localStore.buildingId || ''
  );
  buildingId$ = this.buildingIdSubject
      .asObservable()
      .pipe(map((id) => Number(id)));

  loggedIn$ = this.oidcService.isAuthenticated$;
  private hasLoggedIn = false;
  private loggedInSub$ = this.loggedIn$.subscribe(value => {
    this.hasLoggedIn = value;
  });
  public get isLoggedIn(): boolean {
    this.debug.log('AuthorizationService isLoggedIn check - hasLoggedIn:', this.hasLoggedIn, 'isLoading:', this.loadingSubject.value);
    return this.hasLoggedIn
      //&& !this.loadingSubject.value
    ;
  }
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  user$: Observable<User> = this.oidcService.userData$.pipe(
    filter((v) => Boolean(v)),
    tap(() => this.loaded()),
    shareReplay(1)
  );
  userId$ = this.user$.pipe(
    pluck("user_data"),
    map((ud) => {
      const tmp = ud?.filter(f => f?.length > 0)
        .map(m => {
          try {
            return JSON.parse(m) as IUserDataInfo;
          } catch {
            return null;
          }
        })
        .filter(item => item?.Name.toLowerCase().includes("nsideid"));

      return tmp?.length > 0
        ? tmp[0]
        : { Name: 'ERROR', Value: null } as IUserDataInfo
    }),
    map(v => isNumeric(v?.Value)
      ? v?.Value as number
      : v?.Value as string),
    take(1)
  );
  userRoles$ = this.user$.pipe(pluck('role'));
  userName$ = this.user$
      .pipe(defaultIfEmpty({ name: '' }))
      .pipe(pluck('name'));
  // userData$ = this.user$.pipe(
  //   pluck('user_data'),
  //   map((dataArray) => dataArray.map((data) => JSON.parse(data))),
  //   shareReplay(1)
  // );
  userData$ = this.oidcService.userData$.pipe(shareReplay(1));
  email$ = this.userName$;

  logout() {
    this.oidcService.logoff();
  }

  checkAuth() {
    this.loadingSubject.next(true);
    return this.oidcService.checkAuth();
  }

  authorize() {
    this.loadingSubject.next(true);
    return this.oidcService.authorize();
  }

  loaded() {
    this.loadingSubject.next(false);
  }

  setBuildingId(id: string) {
    this.buildingIdSubject.next(id);
  }

  getToken() {
    return this.oidcService.getToken();
  }

  silentRenewEnabled() {
    return this.oidcService.configuration.configuration.silentRenew;
  }

  silentRenewForceSession() {
    this.oidcService.forceRefreshSession();
  }
}
