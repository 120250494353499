// Developer: Quan N. Nguyen
// Date: 2/23/2023
// Description: Redirect component, for giving a place,
// that nSide|Identity to kick back when done with identity.

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthorizationService } from '@clients-nside-io/shared/auth';

@Component({
  selector: 'nside-tips-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent {

  constructor(
    private router: Router,
    private authService: AuthorizationService
  ) {}

  // if the authentication done, identity will kick back to the app an identity
  // at that time, this route/component will catch this identity
  // and redirect to the satisfy route.
  // else kick the user to unauthorization route.
  isLoggedIn$ = this.authService.checkAuth().pipe(
    map((loggedIn) => {
      if (loggedIn && localStorage.getItem('buildingId')) {

        // navigate to tips queue route.
        this.router.navigate([
          `tips-queue/${localStorage.getItem('buildingId')}`
        ]);
        return true;
      } else {
        // navigate to unauthorized route.
        this.router.navigate(['unauthorized']);
        return false;
      }
    })
  );

}
