import { Component } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import {
  Categories,
  TipFilterState,
  TipItem,
} from '@clients-nside-io/shared/models';
import { TipsService } from '../tips.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'clients-nside-io-tips-list',
  templateUrl: './tips-list.component.html',
  styleUrls: ['./tips-list.component.scss'],
})
export class TipsListComponent {
  constructor(public tipsService: TipsService) {}
  error$ = new BehaviorSubject<boolean>(false);
  items$: Observable<TipItem> = this.tipsService.tips$.pipe(
    catchError(() => {
      this.error$.next(true);
      return EMPTY;
    }),
    shareReplay(1)
  );
  categories = Categories;
  tipState = TipFilterState;
  tipFormLink = `${environment.tipsClientBaseUrl}/tips`;

  paginationClick(url: string) {
    this.tipsService.emitNextUrl(url);
  }

  onFilter(filter) {
    this.tipsService.emitNextUrl('');
    this.tipsService.filterTips(filter);
  }
}
