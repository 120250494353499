// Developer: Quan N. Nguyen
// Date: 2/23/2023
// Description: Home landing page for Tips Dash

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'tips-home',
  templateUrl: './tips-queue-home.component.html',
  styleUrls: ['./tips-queue-home.component.css'],
})
export class TipsQueueHomeComponent implements OnInit {
  private paramBuildingId: number | string | null = null;

  constructor(
    private activatedRouted: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    // handling input on uri
    this.inputBuildingFromParams();
  }

  
  private inputBuildingFromParams() {
    // get building id from the route uri
    this.paramBuildingId =
      this.activatedRouted.snapshot.paramMap.get('buildingId');

    // if there is no building id on the uri.
    if (!this.paramBuildingId) {
      // getting the buildingid from localstorage
      const buildingIdFromLocalStorage = localStorage.getItem('buildingId');

      // if there is an building id from localstorage, then navigate
      // to the tips dash of this building.
      if (buildingIdFromLocalStorage) {
        this.router.navigate([`tips-queue/${buildingIdFromLocalStorage}`]);
      } else {
      // else throw to error view.
        this.router.navigate([`error`]);
      }
    }
  }
}
