import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthModule, OidcConfigService } from 'angular-auth-oidc-client';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  AuthInterceptor,
  AuthorizationGuard,
} from '@clients-nside-io/shared/auth';
import { TipsQueueHomeComponent } from './tips-queue-home/tips-queue-home.component';
import { TipsListComponent } from './tips-list/tips-list.component';
import { TipsCardComponent } from './tips-card/tips-card.component';
import { RemoveUnderscorePipe } from '@clients-nside-io/shared/util';
import { TipsDialogComponent } from './tips-dialog/tips-dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '@clients-nside-io/shared/components';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RedirectComponent } from './redirect/redirect.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ErrorComponent } from './error/error.component';

export function configureAuth(
  oidcConfigService: OidcConfigService
  //eslint-disable-next-line
): () => Promise<any> {
  return () => oidcConfigService.withConfig(environment.identityConfig);
}
// Developer: Quan N. Nguyen
// Date: 2/23/2023
// Description: Routes list for the app.
const routes: Routes = [
  {
    path: '',
    component: TipsQueueHomeComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'tips-queue/:buildingId',
    component: TipsQueueHomeComponent,
    canActivate: [AuthorizationGuard],
  },
  // ---- below routes are required to work well with nSide|Identity.
  {
    path: 'redirect',
    component: RedirectComponent,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: '**',
    component: ErrorComponent,
  }
  // ----------------------------------------------------------------
];
@NgModule({
  declarations: [
    AppComponent,
    TipsQueueHomeComponent,
    TipsListComponent,
    TipsCardComponent,
    RemoveUnderscorePipe,
    TipsDialogComponent,
    RedirectComponent,
    UnauthorizedComponent,
    ErrorComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    AuthModule.forRoot(),
    MaterialModule,
    FormsModule,
    BrowserAnimationsModule,
  ],
  providers: [
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuth,
      deps: [OidcConfigService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    MatDialog,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
