<!-- <ng-container *ngIf="buttonGroup$ | async as buttons"> -->
<div class="tool-bar-container">
  <div class="tip-filter-container">
    <label for="tip-filter"></label>
    <select id="tip-filter" (change)="onFilter($event.target.value)">
      <option [value]="tipState.All">All Tips</option>
      <option [value]="tipState.Opened">Open</option>
      <option [value]="tipState.InReview">In review</option>
      <option [value]="tipState.Closed">Closed</option>
    </select>
  </div>
  <div class="copy-link">
    <label><a target="_blank" [href]="tipFormLink">Submit Tip: </a></label>
    <input
      (click)="$event.target.select()"
      class="link-input"
      [value]="tipFormLink"
      readonly
    />
    <button
      class="copy-btn"
      [cdkCopyToClipboard]="tipFormLink"
      [matTooltip]="tipFormLink"
    >
      <mat-icon [inline]="true">content_copy</mat-icon>
    </button>
  </div>
  <!-- <div class="filter-btn-container">
      <button (click)="intervalRefresh(5000)">5</button>
        <button (click)="intervalRefresh(null)">STOP</button>
      <ng-container *ngFor="let button of buttons">
        <button
          class="{{ button.value }} btn"
          [ngClass]="{ off: !button.on }"
          (click)="handleButtonClick(button.value)"
        >
          {{ button.value | removeUnderscore }}
        </button>
      </ng-container>
      <button class="filter-reset-btn" mat-mini-fab (click)="refresh()">
        <mat-icon>autorenew</mat-icon>
      </button>
    </div> -->
  <!-- <div class="filter-date-container">
      <mat-form-field>
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input
            [(ngModel)]="startDate"
            matStartDate
            placeholder="Start date"
          />
          <input
            [(ngModel)]="endDate"
            matEndDate
            placeholder="End date"
            (dateChange)="handleDateChange()"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
      <button
        id="date-clear-btn"
        mat-mini-fab
        color="accent"
        type="button"
        (click)="clearDate()"
      >
        X
      </button>
    </div> -->
  <!-- <div class="filter-spam-container">
      <mat-checkbox (change)="toggleSpam()">Include Spam</mat-checkbox>
    </div>
    <div class="interval-container">
      <ng-container *ngIf="selectedInterval.value === null; else offBtn">
        <mat-form-field>
          <mat-label>Select refresh interval</mat-label>
          <mat-select (selectionChange)="intervalRefresh($event.value)">
            <mat-option
              *ngFor="let time of intervals | slice: 1"
              [value]="time"
            >
              {{ time.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <ng-template #offBtn>
        <div class="interval-reset-container">
          <p>Refresh: {{ selectedInterval.viewValue }}</p>
          <button
            mat-raised-button
            color="accent"
            (click)="intervalRefresh(intervals[0])"
          >
            Off
          </button>
        </div>
      </ng-template>
    </div> -->
</div>
<!-- </ng-container> -->
<div *ngIf="error$ | async">
  There was an error please refresh and try again
</div>
<ng-container *ngIf="items$ | async as items">
  <div class="table-headers">
    <h3>Details</h3>
    <h3>Tip Information</h3>
    <h3>Actions</h3>
  </div>
  <div class="transcript-list-container">
    <!-- <ng-container *ngIf="items.status === 'success'"> -->
    <ng-container *ngIf="items?.tips?.length > 0; else noData">
      <ng-container *ngFor="let tip of items.tips">
        <clients-nside-io-tips-card
          [class]="tip?.detailRecord?.data?.tipStatus"
          [tip]="tip"
          [audioLink]="tip._links.detail.href"
        ></clients-nside-io-tips-card>
      </ng-container>
    </ng-container>
    <ng-template #noData>
      <div class="error-message">
        <p>There are no notifications available.</p>
      </div>
    </ng-template>
    <!-- </ng-container> -->
    <!-- <mat-spinner *ngIf="items.status === 'loading'"></mat-spinner>
    <div class="error-message" *ngIf="items.status === 'failure'">
      <p>There was an error retrieving notifications.</p>
    </div> -->
  </div>
  <div class="transcript-list-footer">
    <div class="page-display">
      <p>
        Showing {{ items.pagingInfo?.startNum || 0 }} to
        {{
          items.data?.length > 0
            ? items.pagingInfo?.startNum + items.data?.length - 1
            : 0
        }}
        of
        {{ items.pagingInfo?.totalRecords || 0 }}
      </p>
    </div>
    <div class="pagination-btn-container">
      <button
        mat-raised-button
        color="primary"
        (click)="paginationClick(items._links?.prevPage?.href)"
        [disabled]="!items._links?.prevPage"
      >
        &larr; Prev
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="paginationClick(items._links?.nextPage?.href)"
        [disabled]="!items._links?.nextPage"
      >
        Next &rarr;
      </button>
    </div>
  </div>
</ng-container>
