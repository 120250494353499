<ng-container>
  <div class="details-container">
    <p>{{ tip.data.received | date: 'short' }}</p>
    <p>
      {{ tip.type | removeUnderscore }}
      <!-- <span class="percentile">{{
        tip.data.categoryConfidence | percent
      }}</span> -->
    </p>
    <p>{{ tip.data.type | removeUnderscore: ', ' }}</p>
    <a class="building-link" href="">{{ tip.data.buildingName }}</a>
  </div>

  <div [ngClass]="{ opened: opened }" class="transcript-container" #transcript>
    <ng-container
      *ngFor="let result of tip.rawTip[0].data.data; let idx = index"
    >
      <div class="tip">
        <p class="p-2">{{ result.question }}</p>
        <p class="p-2">
          {{ idx === 0 ? result.schoolName : (result.answer | json) }}
        </p>
      </div>
    </ng-container>
    <!-- <p>
      {{ tip.theTip.data.data[8].question }}
    </p>
    <p>{{ tip.theTip.data.data[8].answer | json }}</p>
    <p>
      {{ tip.theTip.data.data[9].question }}
    </p>
    <p>{{ tip.theTip.data.data[9].answer | json }}</p>
    <p>
      {{ tip.theTip.data.data[10].question }}
    </p>
    <p>{{ tip.theTip.data.data[10].answer | json }}</p> -->
    <button
      (click)="toggleOpen()"
      class="expand-btn btn"
      *ngIf="collapseBtnShow"
    >
      {{ opened ? 'Less' : 'More' }}
    </button>
  </div>

  <div>
    <!-- <span class="percentile">{{
      tip.transcript.tip.confidence | percent
    }}</span> -->
  </div>

  <div class="action-container">
    <button (click)="openDialog()">Manage</button>
    <!-- <button
      class="audio-btn btn"
      type="button"
      (click)="togglePlay(audio)"
      [innerHTML]="isPlaying ? '&#x23f8;' : '&#x23F5;'"
    ></button>
    <audio
      src="{{ tip.recordingUrl }}"
      #audio
      (ended)="togglePlay(audio)"
    ></audio> -->
  </div>
</ng-container>
