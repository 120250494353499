<div *ngIf="userName$ | async as user" class="dialog-container">
  <div class="radio-btn-container">
    <label>Select the current status</label>
    <mat-radio-group [(ngModel)]="status" name="status">
      <mat-radio-button [value]="'open'">Open</mat-radio-button>
      <mat-radio-button [value]="'review'">In Review</mat-radio-button>
      <mat-radio-button [value]="'closed'">Closed</mat-radio-button>
    </mat-radio-group>
  </div>
  <mat-form-field>
    <mat-label>Add Notes</mat-label>
    <textarea
      [(ngModel)]="userNotes"
      class="notes-textarea"
      [matTextareaAutosize]
      rows="5"
      [matAutosizeMaxRows]="8"
      matInput
    ></textarea>
  </mat-form-field>
  <p class="mb-1">Notes History</p>
  <mat-accordion>
    <mat-expansion-panel *ngFor="let note of notes" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title
          >{{ note.user.Email || note.user.email }}
        </mat-panel-title>
        <mat-panel-description>
          {{ note.timestamp | date: 'short' }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>Note: {{ note.note }}</p>
    </mat-expansion-panel>
  </mat-accordion>
  <div>
    <button mat-raised-button color="warning" (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" (click)="save(user)">Save</button>
  </div>
</div>
