import { IEnvironment } from '@clients-nside-io/shared/models';

export const environment: IEnvironment = {
  production: false,
  debugging: false,
  platformBaseUrl: 'https://staging.nside.io',
  platformDefaultUrl: 'https://staging.nside.io/Default.aspx',
  notifyApiBase: 'https://notify-api.staging.nside.io',
  notifyApiVersion: 'v3.1',
  formApiBase: 'https://api.staging.nside.io',
  formApiVersion: 'v3.0',
  tipsClientBaseUrl: 'https://tips.staging.nside.io',
  identityConfig: {
    stsServer: 'https://identity.staging.nside.io',
    redirectUrl: `${window.location.origin}`,
    postLogoutRedirectUri: `${window.location.origin}`,
    clientId: 'nSide-Notify',
    scope: 'api.staging.nside.io openid roles profile offline_access',
    responseType: 'code',
  },
};
