// Developer: Quan N. Nguyen
// Date: 2/23/2023
// Description: view for handling users fail on authentication.

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nside-tips-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
