// Developer: Quan N. Nguyen
// Date: 2/23/2023
// Description: Error component, for throwing error.

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nside-tips-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
